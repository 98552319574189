.VideoCapture {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: #00000066; /* Black w/ opacity */

  .Content {
    background-color: #fefefe;
    margin: 5% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 80%; /* Could be more or less, depending on screen size */
    max-width: 700px;

    display: flex;
    flex-direction: column;

    video {
      align-self: center;
      width: 400px;
    }

    button {
      margin: 5px auto;
    }

    button:last-child {
      margin: 0;
      align-self: flex-end;
    }
  }
}
